<template>
  <b-modal
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    hide-footer=""
    size="md"
    :title="$t('payments.titles.sendInvoice')"
    @hidden="handleClose"
  >
    <b-container class="p-0">
      <b-row>
        <b-col sm="12" class="mt-2 text-center">
            <p class="mb-0"><strong>{{ $t('payments.subtitles.confirmBeforeSend') }}</strong></p>
        </b-col>
      </b-row>

      <b-form>
        <b-row>
          <b-col sm="9" class="mt-3">
            <label for="driver-phone"><strong>{{ $t('payments.labels.phone') }}</strong></label>
            <the-mask
              id="driver-phone"
              v-model.trim="currentDriver.phone_number"
              type="text"
              :disabled="true"
              class="form-control"
              :mask="['+## (##) #####-####', '+## (##) ####-####']"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="9" class="mt-3">
            <label for="driver-email"><strong>{{ $t('payments.labels.email') }}</strong></label>
            <b-form-input
              id="driver-email"
              v-model.trim="driverEmail"
              type="email"
              :disabled="block_ui"
            >
            </b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12" class="mt-3">
            <label for="driver-note"><strong>{{ $t('payments.labels.comments') }}</strong></label>
            <b-form-textarea
              id="driver-note"
              v-model="driverNote"
              :placeholder="$t('payments.inputs.placeholder.comments')"
              rows="3"
              max-rows="6"
              :disabled="block_ui"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-form>
      <b-row>
          <b-col md="6" class="mt-4">
            <b-button
              variant="secondary"
              block
              data-test="modal-transaction__btn-save"
              @click="closeModal"
              :disabled="block_ui"
            >
              {{ $t('payments.buttons.cancel') }}
            </b-button>
          </b-col>
          <b-col md="6" class="mt-4">
            <b-button
              variant="success"
              block
              data-test="modal-transaction__btn-save"
              @click="sendInvoice()"
              :disabled="block_ui"
            >
              {{ $t('payments.buttons.send') }}
            </b-button>
          </b-col>
        </b-row>
    </b-container>
  </b-modal>
</template>


<script>
import SEND_INVOICE from '@graphql/transaction/mutations/send-invoice.gql';
import { mapGetters } from 'vuex'

export default {
  name: 'ModalSendInvoice',
  data () {
    return {
      driverEmail: '',
      driverNote: '',
      block_ui: false,
    }
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
    modalData: {
      type: Object
    }
  },
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  computed: {
    ...mapGetters('driver', ['header']),
    currentDriver () {
      this.driverEmail = this.header.email

      return this.header;
    },
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      }
    },
  },
  methods: {
    closeModal () {
      this.driverEmail = this.currentDriver.email
      this.driverNote = ''

      this.$emit('closeModalSendInvoice')
    },
    sendInvoice () {
      this.block_ui = true

      this.$apollo
        .mutate({
          mutation: SEND_INVOICE,
          variables: {
            id: this.modalData.id,
            email: this.driverEmail,
            internal_comments: this.driverNote,
          }
        })
        .then(() => {
          this.$emit('successSendInvoice')
          this.$emit('closeModalSendInvoice')
        })
        .catch(err => {
          this.block_ui = false
          this.$emit('errorSendInvoice')
        })
    },
    handleClose() {
      this.$emit('change', false);
    }
  }
}
</script>

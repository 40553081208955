var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "md",
        title: _vm.$t("payments.titles.sendInvoice"),
      },
      on: { hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c(
        "b-container",
        { staticClass: "p-0" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-2 text-center", attrs: { sm: "12" } },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("payments.subtitles.confirmBeforeSend"))
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { sm: "9" } },
                    [
                      _c("label", { attrs: { for: "driver-phone" } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("payments.labels.phone"))),
                        ]),
                      ]),
                      _c("the-mask", {
                        staticClass: "form-control",
                        attrs: {
                          id: "driver-phone",
                          type: "text",
                          disabled: true,
                          mask: ["+## (##) #####-####", "+## (##) ####-####"],
                        },
                        model: {
                          value: _vm.currentDriver.phone_number,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentDriver,
                              "phone_number",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "currentDriver.phone_number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { md: "9" } },
                    [
                      _c("label", { attrs: { for: "driver-email" } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("payments.labels.email"))),
                        ]),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          id: "driver-email",
                          type: "email",
                          disabled: _vm.block_ui,
                        },
                        model: {
                          value: _vm.driverEmail,
                          callback: function ($$v) {
                            _vm.driverEmail =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "driverEmail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { md: "12" } },
                    [
                      _c("label", { attrs: { for: "driver-note" } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("payments.labels.comments"))),
                        ]),
                      ]),
                      _c("b-form-textarea", {
                        attrs: {
                          id: "driver-note",
                          placeholder: _vm.$t(
                            "payments.inputs.placeholder.comments"
                          ),
                          rows: "3",
                          "max-rows": "6",
                          disabled: _vm.block_ui,
                        },
                        model: {
                          value: _vm.driverNote,
                          callback: function ($$v) {
                            _vm.driverNote = $$v
                          },
                          expression: "driverNote",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-4", attrs: { md: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "secondary",
                        block: "",
                        "data-test": "modal-transaction__btn-save",
                        disabled: _vm.block_ui,
                      },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("payments.buttons.cancel")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mt-4", attrs: { md: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "success",
                        block: "",
                        "data-test": "modal-transaction__btn-save",
                        disabled: _vm.block_ui,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sendInvoice()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("payments.buttons.send")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }